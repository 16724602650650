import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import { useActiveWalletChain, useActiveWallet } from "thirdweb/react";
import { ethers } from "ethers";
import { claimKeeper } from "../../assets/abi/TokenClaimKeeper";
import { erc20 } from "../../assets/abi/Erc20";

import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const EnviosPreparados = ({
  projeto,
  envios,
  setLoadingModal,
  toggle,
  loadingModal,
}) => {
  const chain = useActiveWalletChain();
  const wallet = useActiveWallet();
  const [isAdmin, setIsAdmin] = useState(false);
  const [carteirasContrato, setCarteirasContrato] = useState([]);
  const [loading, setLoading] = useState(true);

  const excluirEnvio = async (id) => {
    const response = await apiClient.delete(`/registro-envios/excluir/${id}`);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setLoadingModal(true);
      setLoading(true);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  };

  const getCarteiras = useCallback(async (contrato_id) => {
    const response = await apiClient.get(`/carteiras/contrato/${contrato_id}`);
    if (response.sucesso) {
      setCarteirasContrato(response.dados);
      setIsAdmin(checkCarteiras(wallet?.getAccount()?.address, response.dados));
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  const checkCarteiras = (carteira, carteiras) => {
    let returnValue = false;
    carteiras.forEach((carteiraContrato) => {
      if (carteira.toLowerCase() === carteiraContrato.carteira.toLowerCase()) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  const updateHash = async (hash, envio, avisar=true) => {
    const response = await apiClient.put(
      `/registro-envios/update-hash/${envio.id}`,
      {
        hash: hash ? hash : "0x0",
        carteira: wallet?.getAccount()?.address,
      }
    );
    if (response.sucesso) {
      if(avisar) toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  };

  const envioUnico = async (envio, projeto) => {
    const dadosEnvio = JSON.parse(envio.data);
    if (chain.id !== projeto.token.rede.network_id) {
      toast.error("Mude para a rede correta para enviar os tokens");
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenAddress = projeto.token.contrato;

    const contrato = projeto.contrato?.contrato;
    if (!contrato) return;

    let receipt;
    try {
      const contract = new ethers.Contract(contrato, claimKeeper, signer);
      const carteiras = dadosEnvio.carteiras;
      let estimateGas;

      const quantidadesBigNumber = dadosEnvio.amounts.map((qtd) =>
        ethers.BigNumber.from(qtd.toString().split(".")[0])
      );

      //faça um laço para fazer um console.log de todos os valores em quantidadesBigNumber **de forma legivel (em string, não em bigNumber)** no Console do navegador
      quantidadesBigNumber.forEach((qtd) => {
        console.log(ethers.utils.formatUnits(qtd.toString(), projeto.token.decimais));
      });

      try {
        estimateGas = await contract.estimateGas.depositTokens(
          tokenAddress,
          quantidadesBigNumber,
          carteiras
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setLoading(false);
        return;
      }

      const transactionResponse = await contract.depositTokens(
        tokenAddress,
        quantidadesBigNumber,
        carteiras,
        {
          gasLimit: estimateGas,
        }
      );
      // get the transaction hash before waiting
      let txHash = await transactionResponse.hash;
      await updateHash(txHash, envio, false);
      receipt = await transactionResponse.wait();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar transação");
      setLoading(false);
      return;
    }
    await updateHash(receipt.transactionHash, envio);
  };

  const envioGeral = async (envios, projeto) => {
    setLoading(true);
    if (chain.id !== projeto.token.rede.network_id) {
      toast.error("Mude para a rede correta para enviar os tokens");
      return;
    }

    const contrato = projeto.contrato?.contrato;
    if (!contrato) return;

    const totalAmount = envios.reduce((total, envio) => {
      const amount = ethers.BigNumber.from(
        envio.total_token.toString().split(".")[0]
      );
      return total.add(amount);
    }, ethers.BigNumber.from(0));

    console.log(totalAmount);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        projeto.token.contrato,
        erc20,
        signer
      );

      let estimateGas;
      try {
        estimateGas = await contract.estimateGas.approve(contrato, totalAmount);
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setLoading(false);
        return;
      }

      const approveResponse = await contract.approve(contrato, totalAmount, {
        gasLimit: estimateGas,
      });
      await approveResponse.wait();

      for (let i = 0; i < envios.length; i++) {
        await envioUnico(envios[i], projeto);
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar transação");
    }
    setLoadingModal(true);
    setLoading(false);
    toggle();
  };

  useEffect(() => {
    const fetchCarteiras = async () => {
      await getCarteiras(envios[0].projeto.contrato.id);
    };
    if (envios) {
      if (envios.length > 0) fetchCarteiras();
      setLoading(false);
    }
  }, [envios, loadingModal]);

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title">Envios Preparados</h4>
      </CardHeader>
      <CardBody>
        <div className="live-preview">
          <ListGroup>
            {loading ? (
              <Row className="justify-content-center">
                <Spinner />
              </Row>
            ) : (
              envios.map((envio, index) => {
                let totalAmount = ethers.BigNumber.from(
                  envio.total_token.toString().split(".")[0]
                );
                totalAmount = totalAmount.div(
                  ethers.BigNumber.from(10).pow(projeto.token.decimais)
                );

                const totalClientes = JSON.parse(envio.data).carteiras.length;

                return (
                  <ListGroupItem key={index}>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <FeatherIcon
                          className="avatar-xs rounded-circle text-warning"
                          icon="package"
                        />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        {`${totalAmount} ${projeto?.token?.sigla} | `}
                        <small className="text-muted">
                          Clientes: {totalClientes}
                        </small>
                      </div>
                      <div className="ml-auto me-2">
                        {loading ? (
                          <Spinner />
                        ) : (
                          <>
                            <Link
                              title="Remover transação"
                              onClick={() => excluirEnvio(envio.id)}
                            >
                              <FeatherIcon
                                className="text-danger me-2"
                                width="20"
                                icon="minus-circle"
                              />
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </ListGroupItem>
                );
              })
            )}
          </ListGroup>
          <small className="text-muted">
            Carteira conectada: {wallet?.getAccount()?.address}
          </small>
          {!isAdmin && (
            <>
              <br />
              <small className="text-warning">
                Será cobrado 1% do valor total do envio para esta transação.
              </small>
            </>
          )}
        </div>
        <Row className="mt-3 justify-content-end">
          {loading || loadingModal ? (
            <Link to="#" className="btn btn-success">
              <Spinner size="sm" /> Processando...
            </Link>
          ) : (
            <Link
              to="#"
              className="btn btn-success"
              onClick={() => envioGeral(envios, projeto)}
            >
              Processar Envios
            </Link>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default EnviosPreparados;
