import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

import {
  useSwitchActiveWalletChain,
  useActiveWallet,
  useActiveWalletChain,
} from "thirdweb/react";
import { defineChain } from "thirdweb/chains";
import BotaoConectar from "../Authentication/BotaoConectar";
import EnviosPreparados from "./EnviosPreparados";

const apiClient = new APIClient();

const ModalEnviar = ({ modalEnviar, toggle, setLoading, projeto }) => {
  const carteira = useActiveWallet();
  const chain = useActiveWalletChain();
  const switchChain = useSwitchActiveWalletChain();

  const [loadingModal, setLoadingModal] = useState(true);
  const [envios, setEnvios] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalClientes, setTotalClientes] = useState(0);
  const [qtdEnvios, setQtdEnvios] = useState(0);

  const toggleModal = () => {
    setEnvios([]);
    setTotalAmount(0);
    setTotalClientes(0);
    setQtdEnvios(0);
    setLoading(true);
    setLoadingModal(true);
    toggle();
  };

  const prepararEnvios = async (projeto) => {
    setLoadingModal(true);

    const response = await apiClient.post(
      `/registro-envios/preparar/${projeto.id}`
    );
    if (response.sucesso) {
      setEnvios(response.dados);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadingModal(false);
  };

  const getEnviosData = useCallback(
    async (projeto) => {
      const response = await apiClient.get(
        `/registro-envios/ver-preparo/${projeto.id}`
      );
      if (response.sucesso) {
        setTotalAmount(
          parseFloat(ethers.utils.formatUnits(
            response.dados.total_amount.toString(), 
            projeto?.token?.decimais || 18
          ))
        );
        setTotalClientes(response.dados.total_clientes);
        setQtdEnvios(response.dados.qtd_envios);
      } else {
        console.error(response.mensagem);
        toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
      setLoadingModal(false);
    },
    [apiClient]
  );

  const getEnvios = useCallback(
    async (projeto) => {
      setLoadingModal(true);
      const response = await apiClient.get(
        `/registro-envios/nao-realizados/${projeto.id}`
      );
      if (response.sucesso) {
        setLoadingModal(false);
        return response.dados;
      } else {
        console.error(response.mensagem);
        toast.error(response.mensagem);
        return [];
      }
    },
    [apiClient]
  );

  const trocaRede = async (proj) => {
    try {
      await switchChain(
        defineChain({
          id: proj?.token?.rede?.network_id,
          nativeCurrency: {
            name: proj?.token?.rede?.nome,
            symbol: proj?.token?.rede?.sigla,
            decimals: 18,
          },
        })
      );
    } catch (e) {
      console.error(e);
      if (e.code && e.code !== -32002) toast.error("Erro ao trocar de rede");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const execute = async (proj) => {
      const enviosNao = await getEnvios(proj);
      if (enviosNao.length === 0) {
        await getEnviosData(proj);
        setEnvios([]);
      } else setEnvios(enviosNao);
    };

    if (modalEnviar && projeto && loadingModal) {
      execute(projeto);
    }

    if (projeto && projeto?.token && carteira) {
      trocaRede(projeto);
    }
  }, [modalEnviar, projeto, carteira, loadingModal]);

  return (
    <Modal
      isOpen={modalEnviar}
      toggle={() => {
        toggleModal();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggleModal();
        }}
      >
        Realizar Envio no Projeto {projeto?.nome || ""}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        {loadingModal ? (
          <Row className="mt-3 justify-content-center">
            <Spinner />
          </Row>
        ) : envios.length === 0 ? (
          <div className="mt-4">
            <Row className="mt-3">
              <h4 className="fw-bold">Confirmação de Preparação de Envio</h4>
              <small className="text-muted">
                {projeto?.nome
                  ? `${projeto.nome} (${projeto?.token?.nome})`
                  : ""}
              </small>
              <p className="mt-5" style={{ fontSize: "1rem" }}>
                A ação irá preparar o envio de todos os arquivos do projeto para{" "}
                <b className="text-info">{totalClientes}</b> clientes,
                totalizando <b className="text-warning">{totalAmount}</b> tokens
                em <b className="text-success">{qtdEnvios}</b> envios.
              </p>
            </Row>
            <div className="hstack gap-2 justify-content-end mt-5">
              <Link
                to="#"
                className="btn btn-link link-success fw-medium"
                onClick={() => toggleModal()}
                disabled={loadingModal}
              >
                <i className="ri-close-line me-1 align-middle"></i> Fechar
              </Link>
              <Link
                to="#"
                className="btn btn-success"
                onClick={() => prepararEnvios(projeto)}
                disabled={loadingModal || !carteira}
              >
                Preparar Envios
              </Link>
            </div>
          </div>
        ) : !carteira ? (
          <Row className="justify-content-center mt-3">
            <BotaoConectar />
          </Row>
        ) : chain?.id !== projeto?.token?.rede?.network_id ? (
          <Row className="mt-3 justify-content-center">
            <Button
              color="danger"
              onClick={() => trocaRede(projeto)}
              disabled={loadingModal}
            >
              Trocar para rede {projeto?.token?.rede?.nome}
            </Button>
          </Row>
        ) : (
          <EnviosPreparados
            projeto={projeto}
            envios={envios}
            setLoadingModal={setLoadingModal}
            toggle={toggleModal}
            loadingModal={loadingModal}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalEnviar;
