import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Spinner,
  Button,
} from "reactstrap";

import { Link, useParams } from "react-router-dom";
import Paginacao from "../../Components/Common/Paginacao";
import ModalFixHash from "./ModalFixHash";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import SearchOption from "./SearchOption";
import { claimKeeper } from "../../assets/abi/TokenClaimKeeperV3";
import { ethers } from "ethers";
import {
  useSwitchActiveWalletChain,
  useActiveWallet,
  useActiveWalletChain,
} from "thirdweb/react";
import BotaoConectar from "../Authentication/BotaoConectar";
import { defineChain } from "thirdweb/chains";

import {
  formatAddress,
  converterData,
} from "../../Components/FunctionsRepository";

import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const apiClient = new APIClient();

const ClaimProjeto = () => {
  const carteira = useActiveWallet();
  const chain = useActiveWalletChain();
  const switchChain = useSwitchActiveWalletChain();

  const { projeto_id } = useParams();
  const [userDados, setUserDados] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);
  const [loadClaim, setLoadClaim] = useState(false);

  const [claims, setClaims] = useState([]);
  const [claim, setClaim] = useState({});
  const [projeto, setProjeto] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");

  const [modalFixHash, setModalFixHash] = useState(false);

  const toggleModalFixHash = (claim) => {
    setClaim(claim);
    setModalFixHash(!modalFixHash);
  };

  const updateHash = async (hash, projeto, wallet, avisar = true) => {
    console.log(hash, projeto);
    const response = await apiClient.put(`/claim/update-hash/${projeto.id}`, {
      hash,
      carteira: wallet,
    });
    if (response.sucesso) {
      if (avisar) toast.success(response.mensagem);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  };

  //get projeto data
  const fetchProjeto = useCallback(
    async (projeto_id) => {
      const response = await apiClient.get(`/projetos/${projeto_id}`);
      if (response.sucesso) {
        setProjeto(response.dados);
        console.log(response.dados);
      } else {
        console.error(response.mensagem);
        toast.error(response.mensagem);
      }
    },
    [projeto_id]
  );

  const fetchClaim = useCallback(async (params = {}) => {
    const response = await apiClient.post("/claim/buscar/all", {
      ...params,
    });

    if (response.sucesso) {
      setClaims(response.dados.data);
      setTotalItems(response.dados.total);
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem);
    }
  }, []);

  const trocaRede = async (proj) => {
    try {
      await switchChain(
        defineChain({
          id: proj?.token?.rede?.network_id,
          nativeCurrency: {
            name: proj?.token?.rede?.nome,
            symbol: proj?.token?.rede?.sigla,
            decimals: 18,
          },
        })
      );
    } catch (e) {
      console.error(e);
      if (e.code && e.code !== -32002) toast.error("Erro ao trocar de rede");
      return false;
    }
    return true;
  };

  const getAmountClaim = useCallback(async (projeto, carteira) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenAddress = projeto.token.contrato;

    const contrato = projeto.contrato?.contrato;
    if (!contrato) {
      return 0;
    }

    let amount = 0;
    try {
      const contract = new ethers.Contract(contrato, claimKeeper, signer);
      amount = await contract.getClaimableAmount(tokenAddress, carteira);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar valor claim");
    }
    return amount;
  }, []);

  const enviarClaim = async (projeto, wallet) => {
    setLoadClaim(true);
    if (chain?.id !== projeto.token.rede.network_id) {
      if (!(await trocaRede(projeto))) return;
    }

    const amountClaim = await getAmountClaim(projeto, wallet, true);

    if (amountClaim.lte(0)) {
      toast.error("Não há tokens a serem sacados para esta carteira");
      await updateHash("0x0", projeto, wallet);
      setLoadClaim(false);
      setLoading(true);
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenAddress = projeto.token.contrato;

    const contrato = projeto.contrato?.contrato;
    if (!contrato) {
      toast.error("Erro ao buscar contrato");
      setLoadClaim(false);
      return;
    }

    let receipt;
    try {
      const contract = new ethers.Contract(contrato, claimKeeper, signer);
      let estimateGas;

      try {
        estimateGas = await contract.estimateGas.forceSendToken(
          tokenAddress,
          wallet
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao estimar GAS");
        setLoadClaim(false);
        return;
      }

      const transactionResponse = await contract.forceSendToken(
        tokenAddress,
        wallet,
        {
          gasLimit: estimateGas,
        }
      );
      // get the transaction hash before waiting
      let txHash = await transactionResponse.hash;
      await updateHash(txHash, projeto, wallet, false);
      receipt = await transactionResponse.wait();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar transação");
      setLoadClaim(false);
      return;
    }
    await updateHash(receipt.transactionHash, projeto, wallet);
    setLoading(true);
    setLoadClaim(false);
  };

  useEffect(() => {
    const getProjetos = async () => {
      setUserDados(getLoggedinUser());
      await fetchClaim({
        termo: termoBusca,
        itens_pagina: itemsPerPage,
        pagina: currentPage,
        projeto_id,
      });
      await fetchProjeto(projeto_id);
      setLoading(false);
      setLoadTable(false);
    };
    getProjetos();
  }, [projeto_id, loading, itemsPerPage, currentPage, termoBusca]);

  document.title = "Lista de Claim | Claim My Tokens";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Claim Clientes" pageTitle="Projetos" />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2 mt-2">
              <Col xl={2}>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setCurrentPage(1);
                    setLoading(true);
                  }}
                  value={itemsPerPage}
                >
                  <option>Itens por Página</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </Col>
              <Col xl={10}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setCurrentPage}
                  termoBusca={termoBusca}
                  setLoading={setLoading}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>

          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          Projeto: {projeto?.nome}
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <BotaoConectar />
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    {!loading && !loadTable ? (
                      <table className="table table-hover table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">ID #</th>
                            <th scope="col">Data Enviado</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Quantidade</th>
                            <th scope="col">Data Claim</th>
                            <th scope="col">Hash</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(claims || []).map((claim, index) => {
                            return (
                              <tr key={index}>
                                <td>{claim.id}</td>
                                <td>{converterData(claim.created_at)}</td>
                                <td>
                                  {claim?.cliente?.nome}
                                  <br />
                                  <small className="text-muted">
                                    {claim?.carteira?.carteira}
                                  </small>
                                </td>
                                <td>~ {parseFloat(claim.amount).toFixed(4)}</td>
                                {claim.tx_hash && claim.tx_hash !== "" ? (
                                  <td>{converterData(claim.solicitado)}</td>
                                ) : (
                                  <td className="text-muted">----</td>
                                )}
                                {claim.tx_hash === "0x0" ? (
                                  <td>
                                    <Button
                                      color="warning"
                                      disabled={loadClaim}
                                      className="btn btn-sm"
                                      onClick={() => toggleModalFixHash(claim)}
                                    >
                                      Hash Inválida Registrada
                                    </Button>
                                  </td>
                                ) : claim.tx_hash && claim.tx_hash !== "" ? (
                                  <td className="text-success">
                                    <Link
                                      to={`${projeto?.token?.rede?.block_explorer}/tx/${claim.tx_hash}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {formatAddress(claim.tx_hash)}
                                    </Link>
                                  </td>
                                ) : (
                                  <td>
                                    {projeto?.contrato?.nome !==
                                    "TokenClaimKeeperV3" ? (
                                      <p className="text-danger">
                                        Claim não realizado
                                      </p>
                                    ) : carteira ? (
                                      <Button
                                        color="secondary"
                                        disabled={loadClaim}
                                        className="btn btn-sm"
                                        onClick={() =>
                                          enviarClaim(
                                            projeto,
                                            claim?.carteira?.carteira
                                          )
                                        }
                                      >
                                        Forçar Enviar
                                      </Button>
                                    ) : (
                                      <p className="text-success">
                                        Conecte a carteira para ver opções
                                      </p>
                                    )}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <Row className="justify-content-center mt-3 mb-3">
                        <Spinner color="warning" />
                      </Row>
                    )}
                  </div>
                  {totalItems > itemsPerPage && (
                    <Paginacao
                      total={totalItems}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      perPageData={itemsPerPage}
                    />
                  )}
                </div>
              </Card>
            </Col>

            <ModalFixHash
              modal={modalFixHash}
              toggle={toggleModalFixHash}
              claim={claim}
              setLoading={setLoading}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClaimProjeto;
